@font-face {
  font-family: "NSerif";
  font-display: swap;
  src: url("./fonts/NotoSerifCJKkr-Regular-subset.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
}

body {
  font-family: "NSerif";
  font-size: 20px;
  padding: 9vh 50px 40px;
  letter-spacing: -0.01em;
  background-color: #fbfbfb;
}
body.no-scroll {
  overflow: hidden;
}
body.no-click {
  pointer-events: none;
}

canvas {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  /* opacity: 0; */
  /* pointer-events: none; */
  /* width: 100% !important; */
  /* height: auto !important; */
}
.text-wrap {
}

.single-text-block {
  margin-bottom: 500px;
}
.single-text-block:last-child {
  margin-bottom: 0;
  min-height: 90vh;
  position: relative;
}
.single-text-block .word {
  opacity: 0;
}
.single-text-block > div {
  line-height: 1.7;
}

.single-text-block-0 .shift-0 {
  margin-left: 61px;
}
.single-text-block-0 .shift-1 {
  margin-left: 196px;
}

.single-text-block-1 .shift-0 {
  margin-left: 226px;
}
.single-text-block-1 .shift-1 {
  margin-left: 222px;
}
.single-text-block-1 .shift-2 {
  margin-left: 558px;
}
.single-text-block-1 .shift-3 {
  margin-left: 519px;
}

.credit {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  font-size: 10px;
  letter-spacing: 0.05em;
}

.intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fbfbfb;
  z-index: 3;
  cursor: pointer;
}
.intro-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.intro-text .word {
  opacity: 0;
}
.intro-text .click {
  opacity: 0;
  margin-top: 28px;
  font-size: 12px;
}

/* When the browser is at least 600px and above */
@media screen and (max-width: 1400px) {
  body {
    font-size: 16px;
  }

  .single-text-block-0 .shift-0 {
    margin-left: 49px;
  }
  .single-text-block-0 .shift-1 {
    margin-left: 157px;
  }
  .single-text-block-1 .shift-0,
  .single-text-block-1 .shift-1 {
    margin-left: 132px;
  }
  .single-text-block-1 .shift-2 {
    margin-left: 401px;
  }
  .single-text-block-1 .shift-3 {
    margin-left: 369px;
  }
}

@media screen and (max-width: 1024px) {
  body {
    font-size: 14px;
  }
  .single-text-block-0 .shift-0 {
    margin-left: 43px;
  }
  .single-text-block-0 .shift-1 {
    margin-left: 138px;
  }
  .single-text-block-1 .shift-0,
  .single-text-block-1 .shift-1 {
    margin-left: 90px;
  }
  .single-text-block-1 .shift-2 {
    margin-left: 325px;
  }
  .single-text-block-1 .shift-3 {
    margin-left: 298px;
  }
}

@media screen and (max-width: 768px) {
  .shift {
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 570px) {
  body {
    padding: 6vh 30px 40px;
    font-size: 12px;
  }
  .single-text-block {
    margin-bottom: 290px;
  }
}
.dg.ac {
  opacity: 0.2;
}
